var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("chart", {
    attrs: { options: _vm.theme == "light" ? _vm.light : _vm.dark },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }